import { useEffect } from "react";
import getUserData from "../../api/methods/getUserData";
import Nav from "./layout/nav";
import Sidebar from "./layout/sidebar";
import { useState } from "react";
import initiale from "../../utils/initiale";
import deleteAccount from "../../api/methods/deleteAccount";
import getApiKey from "../../api/methods/getApiKey";
import postApiKey from "../../api/methods/postApiKey";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Link } from "react-router-dom";
import Seo from "../../utils/Seo";
import { SUPPORT_SERVER } from "../../App";

const MySwal = withReactContent(Swal)

const Settings = () => {
    const [user, setUser] = useState(undefined);
    const [apikey, setApiKey] = useState(undefined);

    const switchTab = (name) => {
        var tabs = document.querySelectorAll(".tab");
        tabs.forEach(function (element) {
            element.classList.remove("active");
        });

        const selected_tab = document.getElementById(`${name}_tab`)
        selected_tab?.classList.add("active")

        var tabs = document.querySelectorAll(".tabShow");
        tabs.forEach(function (element) {
            element.classList.remove("active");
        });

        const selected_desc = document.getElementById(`${name}`)
        selected_desc?.classList.add("active")
    }

    const getUserdata_ = async () => {
        const request = await getUserData();
        if (request && request.success === true) {
            setUser(request.data);
        }
    }

    const postApiKey_ = async () => {
        const checkResponse = await postApiKey();
        if (checkResponse && checkResponse.success === true) {
            const api_key = await getApiKey();
            if (api_key && api_key.success === true) {
                if (api_key && api_key.message) {
                    setApiKey(api_key.message);
                } else {
                    setApiKey(api_key.data);
                }
            }
        }
    }

    const copyApiKey = () => {
        navigator.clipboard.writeText(apikey);
        MySwal.fire({
            title: 'Api Key Copied',
            footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
            timer: 3000,
            icon: "success"
        })
    }

    const copyAffiliationLink = () => {
        navigator.clipboard.writeText("https://join4join.xyz/?aff=" + user.id);
        MySwal.fire({
            title: 'Affiliation link Copied',
            footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
            timer: 3000,
            icon: "success"
        })
    }

    const getApiKey_ = async () => {
        const request = await getApiKey();
        if (request && request.success === true) {
            if (request && request.message) {
                setApiKey(request.message);
            } else {
                setApiKey(request.data);
            }
        }
    }

    const deleteAccount_ = async () => {
        Swal.fire({
            title: 'Are you sure you want to delete your account?',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            icon: "question",
            footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire('Account deleted!', 'We hope to see you soon back on join4join.xyz', 'success');
                deleteAccount()
            }
        })
    }

    useEffect(() => {
        getUserdata_()
        getApiKey_();
    }, []);

    return (
        <>
            <Seo dynamic={false} />
            <Sidebar />
            <section id="content">
                <main>
                    <h1 className="title">Settings</h1>
                    <ul className="breadcrumbs">
                        <li><Link to={"/dashboard"}>Home</Link></li>
                        <li className="divider">/</li>
                        <li><a href="#" className="active">Settings</a></li>
                    </ul>
                    <div className="data">
                        <div className="content-data">
                            <div className="settings container">
                                <div className="leftbox">
                                    <div className="nav">
                                        <a id="profile_tab" onClick={() => { switchTab("profile") }} className="tab active">
                                            <i className="fa fa-user"></i>
                                        </a>
                                        <a id="affiliation_tab" onClick={() => { switchTab("affiliation") }} className="tab">
                                            <i class="fa fa-rocket"></i>
                                        </a>
                                        <a id="developer_tab" onClick={() => { switchTab("developer") }} className="tab">
                                            <i class="fa fa-code"></i>
                                        </a>
                                    </div>
                                </div>
                                <div className="rightbox">
                                    <div id="profile" className="profile tabShow active">
                                        <h1>Profile Info</h1>
                                        <h2>Avatar</h2>
                                        <img src={user && user.avatar && user.avatar != "0" ? `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}` : `https://dummyimage.com/400x400/000/ffffff&text=${user ? initiale(user.username) : ""}`} />
                                        <h2>Username</h2>
                                        <input type="text" className="input" value={user ? user.username : undefined} disabled />
                                        <h2>Coins</h2>
                                        <input type="text" className="input" value={user ? user.money : undefined} disabled />
                                        <h2>Created At</h2>
                                        <input type="text" className="input" value={user ? user.createdAt : undefined} disabled />

                                        <div>
                                            <button className="btn red" onClick={() => { deleteAccount_() }}>Delete Account</button>
                                        </div>
                                    </div>
                                    <div id="affiliation" className="affiliation tabShow">
                                        <h1>Affiliation Info</h1>
                                        <h2>Affiliation Link</h2>
                                        <p>Copy your affiliation link and share it. Earn 10 coins per new account from your affiliation link</p>
                                        <div>
                                            <button className="btn" onClick={() => { copyAffiliationLink() }}>Copy</button>
                                        </div>
                                    </div>
                                    <div id="developer" className="developer tabShow">
                                        <h1>Developer Info</h1>
                                        <h2>Api Key</h2>
                                        <p>Create a API key to interact with the Join4Join API!</p>
                                        <div>
                                            {
                                                apikey && apikey === "Create now!" ?
                                                    <><button className="btn" onClick={() => { postApiKey_() }}>Create</button></>
                                                    :
                                                    <><button className="btn" onClick={() => { copyApiKey() }}>Copy</button></>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </section>
        </>
    );
};

export default Settings;