import { useEffect } from "react";
import Footer from "./layout/footer";
import Modal_Share from "../../public/img/modal_share.svg";
import Faq from "./components/faq";
import Feature from "./components/feature";
import About_Us from "./components/about-us";
import Seo from "../../utils/Seo";
import Home from "./components/home";

const Faq_page = () => {
    useEffect(() => {
        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        };

        setTimeout(scrollToTop, 0);
    }, []);

    return (
        <>
            <Seo dynamic={true}
                title={"Join4Join - Frequently Asked Questions (FAQ) - Discord Community Growth, Explained"}
                description={"Find answers to commonly asked questions about Join4Join. Learn how to get members on Discord and overcome challenges with our expert guidance."}
            />
            <Faq />
            <About_Us />
            <Feature />
            <Home />
            <Footer />
        </>
    );
};

export default Faq_page;