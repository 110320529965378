import { useEffect } from "react";
import Footer from "./layout/footer";
import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <>
            <div id="notfound">
                <div class="notfound">
                    <div class="notfound-404">
                        <h1>404</h1>
                        <h2>Page not found</h2>
                    </div>
                    <div>
                        <p>The page you're looking for can't be found.</p>
                    </div>
                    <Link to={"/"}>HomePage</Link>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default NotFound;