import request from '../request'

/**
 * Get database information on the current user
**/

async function getUserData() {
    return request('/join4join/me')
}

export default getUserData