import request from '../request'

/**
 * Give coins for ?farm command
**/

async function refreshRewards() {
    return request('/join4join/farm/command')
}

export default refreshRewards