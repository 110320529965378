const Faq = () => {
    const afq = async (element) => {
        element.querySelector('.rotate').classList.toggle('minus');
        element.classList.toggle('show');
    };

    return (
        <>
            <div className="lp-container service">
                <section className="faq">
                    <div className="section-title">
                        <h1>General Questions</h1>
                    </div>
                    <div className="question-box" onClick={(e) => afq(e.currentTarget)}>
                        <div className="question">
                            <div>
                                <p>What is the join4join method?</p>
                            </div>
                            <div className="btn btn-1">
                                <div></div>
                                <div className="rotate"></div>
                            </div>
                        </div>
                        <div className="answer">
                            <p>The join4join method is a way to promote discord server collaborating with other members.</p>
                        </div>
                    </div>
                    <div className="question-box" onClick={(e) => afq(e.currentTarget)}>
                        <div className="question">
                            <div>
                                <p>What is the purpose of the site?</p>
                            </div>
                            <div className="btn btn-2">
                                <div></div>
                                <div className="rotate"></div>
                            </div>
                        </div>
                        <div className="answer">
                            <p>The purpose of the site is to promote the projects of its members, primarily through Discord.</p>
                        </div>
                    </div>
                    <div className="question-box" onClick={(e) => afq(e.currentTarget)}>
                        <div className="question">
                            <div>
                                <p>Is it free?</p>
                            </div>
                            <div className="btn btn-3">
                                <div></div>
                                <div className="rotate"></div>
                            </div>
                        </div>
                        <div className="answer">
                            <p>Yes, join4join is free to use, allowing members to promote their projects without any cost.
                                However, join4join offers paid options that provide opportunities for accelerated promotion and enhanced features.</p>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default Faq;