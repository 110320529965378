import Seo from "../../../utils/Seo";
import Farm_Page from "../components/farm";
import Nav from "../layout/nav";
import Sidebar from "../layout/sidebar";

const Farm = () => {
    return (
        <>
            <Seo dynamic={false} />
            <Sidebar />
            <section id="content">
                <Farm_Page />
            </section>
        </>
    );
};

export default Farm;