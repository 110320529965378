import request from '../request'

/**
 * Get database information on the current user
**/

async function adminGetUserData(id) {
    return request('/admin/join4join/user/' + id)
}

export default adminGetUserData