import { Link } from "react-router-dom";
import Modal_Stats from "../../../public/img/modal_stats.svg";

const About_Us = () => {
    const isLogin = window.localStorage.getItem("token") ? true : false

    return (
        <>
            <div className="lp-container white">
                <div className="position">
                    <div className="middle">
                        <div className="padding">
                            <div className="left">
                                <div className="left_position">
                                    <img src={Modal_Stats} alt="User displaying statistics"/>
                                </div>
                            </div>
                            <div className="right">
                                <div className="right_position">
                                    <h1>About Us</h1>
                                    <p>We are here to assist individuals in rapidly and effectively growing their projects on Discord. With years of expertise in this field, our team of unique specialists can help you achieve your business goals.</p>
                                    <Link to={isLogin ? "/dashboard" : "/login"} onClick={window.localStorage.setItem("callback", "/dashboard")}>
                                        <button>Discover</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default About_Us;