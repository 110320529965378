import { Link } from "react-router-dom";

const Feature = () => {
    const isLogin = window.localStorage.getItem("token") ? true : false

    return (
        <>
            <div className="lp-container blue service">
                <div className="position">
                    <div className="middle">
                        <h1>Our Features</h1>
                        <div className="feature_list">
                            <div className="feature">
                                <div>
                                    <i className="fa-solid fa-rocket"></i>
                                    <h1>Join4Join Method</h1>
                                </div>
                                <p>Supercharge Your Discord Server with the Explosive Join4Join Technique for Massive Growth!</p>
                                <Link to={isLogin ? "/dashboard" : "/login"} onClick={window.localStorage.setItem("callback", "/dashboard")}>
                                    <button>Try now</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Feature;