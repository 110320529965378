import request from '../request'

async function editAd(guild_id, filter_account, filter_language, type) {
    return request('/join4join/ad/edit', {
        method: 'POST',
        body: JSON.stringify({
            filter_account, filter_language, type, guild_id
        })
    })
}

export default editAd