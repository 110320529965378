import { useEffect } from "react";
import Seo from "../../utils/Seo";
import Footer from "./layout/footer";

const Terms = () => {
    useEffect(() => {
        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        };

        setTimeout(scrollToTop, 0);
    }, []);

    return (
        <>
            <Seo dynamic={false} />
            <div className="legals">
                <h1>GENERAL TERMS AND CONDITIONS OF USE | LAST MODIFICATION: 05/07/2023</h1>
                <h5>By using our services/website, you agree to abide by our GENERAL TERMS AND CONDITIONS OF USE, as well as our PRIVACY/COOKIES POLICY.</h5>
                <br />
                <h2>1. ACCOUNT</h2>
                <br />
                <h4>Registration</h4>
                <p>By creating an account, you certify that you are 13 years of age or older. We reserve the right to delete, modify, or take any necessary actions related to your account.</p>
                <br />
                <h4>Account Security</h4>
                <p>You are responsible for maintaining the security and confidentiality of your account credentials. Any unauthorized access or use of your account should be reported immediately.</p>
                <br />
                <h2>2. OUR SERVICES</h2>
                <br />
                <h4>Modifications and Actions</h4>
                <p>We reserve the right to delete, modify, or take any necessary actions related to our services, as deemed necessary. This may include changes to features, availability, or discontinuation of certain services.</p>
                <br />
                <h4>Usage Restrictions</h4>
                <p>You agree to use our services in compliance with applicable laws and regulations. You shall not engage in any illegal, fraudulent, or unauthorized activities that may cause harm to our platform or other users.</p>
                <br />
                <h4>Intellectual Property</h4>
                <p>All intellectual property rights associated with our services, including but not limited to content, logos, and trademarks, belong to us or our licensors. Unauthorized use or reproduction of our intellectual property is strictly prohibited.</p>
                <br />
                <h2>3. DISCLAIMERS</h2>
                <br />
                <h4>Limitation of Liability</h4>
                <p>We strive to provide accurate and reliable information through our services, but we do not guarantee the completeness, accuracy, or reliability of any content or information. You acknowledge that your use of our services is at your own risk.</p>
                <br />
                <h4>Third-Party Links</h4>
                <p>Our services may contain links to third-party websites or services. We do not endorse or have control over the content, privacy policies, or practices of such third parties. You access third-party links at your own discretion and risk.</p>
                <br />
                <h2>4. TERMINATION</h2>
                <br />
                <h4>Termination of Services</h4>
                <p>We reserve the right to terminate or suspend your access to our services at any time, without prior notice, for any reason including, but not limited to, violation of these terms and conditions.</p>
                <br />
                <h4>Survival of Terms</h4>
                <p>Even after termination, these terms and conditions shall continue to apply to the extent necessary to enforce any rights or obligations that occurred prior to termination.</p>
                <br />
                <p>Please read our complete GENERAL TERMS AND CONDITIONS OF USE and PRIVACY/COOKIES POLICY for more detailed information regarding your rights and responsibilities when using our services.</p>
            </div>
            <Footer />
        </>
    );
};

export default Terms;