import { useState } from "react";
import Nav from "../layout/nav";
import Sidebar from "../layout/sidebar";
import { useEffect } from "react";
import Loading from "../../../utils/loading";
import getUserHistory from "../../../api/methods/getUserHistory";
import { Link } from "react-router-dom";
import Seo from "../../../utils/Seo";

const History_Page = () => {
    const [history_user, setHistory_user] = useState([]);
    const [load, setLoad] = useState(undefined);

    const getUserHistory_ = async () => {
        const request = await getUserHistory();
        if (request && request.success === true) {
            setHistory_user(request.data);
            setLoad(false)
        }
    }

    useEffect(() => {
        getUserHistory_();
        setLoad(true);
    }, []);

    return (
        <>
            <main>
                <h1 className="title">History</h1>
                <ul className="breadcrumbs">
                    <li><Link to={"/dashboard/join4join"}>Join4Join</Link></li>
                    <li className="divider">/</li>
                    <li><a href="#" className="active">History</a></li>
                </ul>
                <div className="data">
                    <div className="content-data">
                        <div className="head.all">
                            <h3 className="title">History Page</h3>
                        </div>
                        <div className="db-container">
                            <div className="history_logs">
                                {load ? <Loading /> :
                                    <>
                                        {history_user.length > 0 ? history_user.map((transaction) => {
                                            return (
                                                <>
                                                    <p class="p_history">{transaction.history}</p>
                                                </>
                                            )
                                        }) : <h1>You don't have any transactions.</h1>}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default History_Page;