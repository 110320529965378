import { Link } from "react-router-dom";
import Logo from "../../../public/img/logoj4j.png";
import { useEffect, useState } from "react";
import getRandomBot from "../../../api/methods/getRandomBot";

const Sidebar = () => {
    const [botID, setBotID] = useState(undefined);

    const menuDropdown = (event) => {
        const selectedDropdown = event.currentTarget.parentElement.querySelector('.side-dropdown');
        const allDropdowns = document.querySelectorAll('.side-dropdown');
        const allLinks = document.querySelectorAll('.side-menu a');

        allLinks.forEach((link) => {
            link.classList.remove('active');
        });

        window.localStorage.removeItem("menuDropdown");

        if (selectedDropdown.classList.contains('show')) {
            selectedDropdown.classList.remove('show');
        } else {
            allDropdowns.forEach((dropdown) => {
                dropdown.classList.remove('show');
            });

            window.localStorage.setItem("menuDropdown", event.currentTarget.innerText);
            selectedDropdown.classList.add('show');
            event.currentTarget.classList.add('active');
        }
    };

    const sideBarMouseEnter = () => {
        const sidebar = document.getElementById('sidebar');
        const allDropdown = document.querySelectorAll('#sidebar .side-dropdown');
        const allSideDivider = document.querySelectorAll('#sidebar .divider');

        if (sidebar.classList.contains('hide')) {
            const nav = document.querySelector("nav");

            if (nav.classList.contains('hide')) {
                nav.classList.remove('hide');
            }

            window.localStorage.removeItem("menuDropdown");

            allDropdown.forEach(item => {
                const a = item.parentElement.querySelector('a:first-child');
                a.classList.remove('active');
                item.classList.remove('show');
            })

            allSideDivider.forEach(item => {
                item.textContent = item.dataset.text;
            })
        }
    }

    const sideBarMouseLeave = () => {
        const sidebar = document.getElementById('sidebar');
        const allDropdown = document.querySelectorAll('#sidebar .side-dropdown');
        const allSideDivider = document.querySelectorAll('#sidebar .divider');

        if (sidebar.classList.contains('hide')) {
            const nav = document.querySelector("nav");

            if (!nav.classList.contains('hide')) {
                nav.classList.add('hide');
            }

            window.localStorage.removeItem("menuDropdown");

            allDropdown.forEach(item => {
                const a = item.parentElement.querySelector('a:first-child');
                a.classList.remove('active');
                item.classList.remove('show');
            })
            allSideDivider.forEach(item => {
                item.textContent = '-'
            })
        }
    }

    let IsmenuDropdown = window.localStorage.getItem("menuDropdown");

    if (window.location.pathname === "/dashboard" && !IsmenuDropdown) {
        IsmenuDropdown = "Manage";
    }

    const removeDropDown = (event) => {
        const allDropdown = document.querySelectorAll('#sidebar .side-dropdown');

        const promises = [];

        allDropdown.forEach(item => {
            const a = item.parentElement.querySelector('a:first-child');
            a.classList.remove('active');
            item.classList.remove('show');

            const promise = new Promise((resolve) => {
                setTimeout(() => resolve(), 0);
            });

            promises.push(promise);
        });

        const name = event.currentTarget.innerText

        Promise.all(promises).then(() => {
            window.localStorage.setItem("menuDropdown", name);
        });
    };


    const getRandomBot_ = async () => {
        const request = await getRandomBot();
        if (request && request.success === true) {
            if (request?.data?.id) {
                setBotID(request.data.id)
            }
        }
    }

    useEffect(() => {
        if (window.innerWidth < 500) {
            window.localStorage.removeItem("menuDropdown")

            const allDropdown = document.querySelectorAll('#sidebar .side-dropdown');
            const allSideDivider = document.querySelectorAll('#sidebar .divider');
            const sidebar = document.getElementById('sidebar');
            const nav = document.querySelector("nav");

            sidebar.classList.add('hide');
            nav.classList.add('hide');

            allSideDivider.forEach(item => {
                item.textContent = '-'
            })

            allDropdown.forEach(item => {
                const a = item.parentElement.querySelector('a:first-child');
                a.classList.remove('active');
                item.classList.remove('show');
            })
        }

        getRandomBot_();
    }, []);

    return (
        <>
            <section id="sidebar" onMouseEnter={sideBarMouseEnter} onMouseLeave={sideBarMouseLeave}>
                <Link to={"/"} className="brand"><img src={Logo} /> Join4Join</Link>
                <ul className="side-menu">
                    {/* <li>
                        <Link to={"/dashboard"} onClick={removeDropDown.bind(this)} className={IsmenuDropdown && IsmenuDropdown === "Dashboard" ? "active" : undefined}>
                            <i className='bx bxs-dashboard icon'></i>Dashboard
                        </Link>
                    </li> */}
                    <li className="divider" data-text="join4join">Join4Join</li>
                    <li>
                        <Link to={"/dashboard"} onClick={removeDropDown.bind(this)} className={IsmenuDropdown && IsmenuDropdown === "Manage" ? "active" : undefined}>
                            <i className='fa fa-store icon' ></i>Manage
                        </Link>
                    </li>
                    <li>
                        <Link to={"/dashboard/join4join/farm"} onClick={removeDropDown.bind(this)} className={IsmenuDropdown && IsmenuDropdown === "Farm" ? "active" : undefined}>
                            <i className='fa fa-rocket icon' ></i>Farm
                        </Link>
                    </li>
                    <li>
                        <Link to={"/dashboard/join4join/check"} onClick={removeDropDown.bind(this)} className={IsmenuDropdown && IsmenuDropdown === "Check" ? "active" : undefined}>
                            <i className='fa-solid fa-circle-check icon' ></i>Check
                        </Link>
                    </li>
                    <li>
                        <Link to={"/dashboard/premium"} onClick={removeDropDown.bind(this)} className={IsmenuDropdown && IsmenuDropdown === "Premium" ? "active" : undefined} style={{ color: "white", backgroundColor: "#bd17f1" }}>
                            <i className='fa-solid fa-cart-arrow-down icon' ></i>Premium
                        </Link>
                    </li>
                    {/* <li className="divider" data-text="main">main</li>
                    <li>
                        <a onClick={menuDropdown} className={IsmenuDropdown && IsmenuDropdown === "Join4Join" ? "active" : undefined}>
                            <i className='bx bxs-inbox icon'></i>Join4Join<i className='bx bx-chevron-right icon-right'></i>
                        </a>
                        <ul className={IsmenuDropdown && IsmenuDropdown === "Join4Join" ? "side-dropdown show" : "side-dropdown"}>
                            <li><Link to={"/dashboard/join4join/manage"}>Manage</Link></li>
                            <li><Link to={"/dashboard/join4join/farm"}>Farm</Link></li>
                            <li><Link to={"/dashboard/join4join/check"}>Check</Link></li>
                        </ul>
                    </li> */}
                    {/* <li className="divider" data-text="main">Stats</li>
                    <li>
                        <Link to={"/dashboard/affiliation"} onClick={removeDropDown.bind(this)} className={IsmenuDropdown && IsmenuDropdown === "Affiliation" ? "active" : undefined}>
                            <i className='bx bx-line-chart icon' ></i>Affiliation
                        </Link>
                    </li>
                    <li>
                        <Link to={"/dashboard/leaderboard"} onClick={removeDropDown.bind(this)} className={IsmenuDropdown && IsmenuDropdown === "Leaderboard" ? "active" : undefined}>
                            <i className='bx bxs-pie-chart-alt-2 icon' ></i>Leaderboard
                        </Link>
                    </li> */}
                    <li className="divider" data-text="more">More</li>
                    <li>
                        <Link to={"/dashboard/transfer"} onClick={removeDropDown.bind(this)} className={IsmenuDropdown && IsmenuDropdown === "Transfer" ? "active" : undefined}>
                            <i className='fa fa-arrow-right-arrow-left icon' ></i>Transfer
                        </Link>
                    </li>
                    <li>
                        <Link to={"/dashboard/rewards"} onClick={removeDropDown.bind(this)} className={IsmenuDropdown && IsmenuDropdown === "Rewards" ? "active" : undefined}>
                            <i className='bx bxs-gift icon' ></i>Rewards
                        </Link>
                    </li>
                    <li><a href={botID ? `https://discord.com/api/oauth2/authorize?client_id=${botID}&permissions=application.commands&scope=bot` : undefined} target="_blank" onClick={() => { window.localStorage.removeItem("menuDropdown") }}><i className='bx bxs-plus-circle icon' ></i>Add A Join4Join Bot</a></li>
                    {/* <li>
                        <a onClick={menuDropdown} className={IsmenuDropdown && IsmenuDropdown === "Stats" ? "active" : undefined}>
                            <i className='bx bx-line-chart icon'></i>Stats<i className='bx bx-chevron-right icon-right'></i>
                        </a>
                        <ul className={IsmenuDropdown && IsmenuDropdown === "Stats" ? "side-dropdown show" : "side-dropdown"}>
                            <li><Link to={"/dashboard/join4join/manage"}>Affiliation</Link></li>
                            <li><Link to={"/dashboard/join4join/farm"}>Leaderboard</Link></li>
                        </ul>
                    </li> */}
                    <li className="divider" data-text="stats">Stats</li>
                    <li>
                        <Link to={"/dashboard/affiliation"} onClick={removeDropDown.bind(this)} className={IsmenuDropdown && IsmenuDropdown === "Affiliation" ? "active" : undefined}>
                            <i className='bx bx-line-chart icon' ></i>Affiliation
                        </Link>
                    </li>
                    <li>
                        <Link to={"/dashboard/leaderboard"} onClick={removeDropDown.bind(this)} className={IsmenuDropdown && IsmenuDropdown === "Leaderboard" ? "active" : undefined}>
                            <i className='bx bxs-pie-chart-alt-2 icon' ></i>Leaderboard
                        </Link>
                    </li>
                    {/* <div class="ads">
                        <div class="wrapper">
                            <Link to={"/dashboard/premium"} class="btn-upgrade">Purchase Members</Link>
                            <p>Purchase <span>discord members</span> at the <span>cheapest price</span> you will find!</p>
                        </div>
                    </div> */}
                </ul>
            </section>
        </>
    );
};

export default Sidebar;