import request from '../request'

/**
 * Post api key
**/

async function postApiKey() {
    return request('/join4join/me/api', {
        method: 'POST',
    })
}

export default postApiKey