export default function initiale(phrase) {
    let mots = phrase?.split(" ");
    let initiales = "";

    for (let i = 0; i < mots?.length; i++) {
        let mot = mots[i];
        if (mot.length > 0) {
            initiales += mot[0];
        }
    }

    return initiales;
}