import request from '../request'

/**
 * Get daily rewards
**/
async function getDailyRewards() {
    return new Promise(resolve => {
        window.grecaptcha.ready(async () => {
            window.grecaptcha
                .execute("6LcKKZAjAAAAAN6Rzp8wQU9cwWxi1TIoB9FH_h1E")
                .then(async (token) => {
                    resolve(request('/join4join/me/daily', {
                        method: 'POST',
                        body: JSON.stringify({
                            captcha_token: token
                        })
                    }))
                })
        })
    })
}

export default getDailyRewards