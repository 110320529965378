import request from '../request'

async function addBot(id, token) {
    return request('/join4join/bots/add', {
        method: 'POST',
        body: JSON.stringify({
            id, token
        })
    })
}

export default addBot