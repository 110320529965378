import { useEffect } from "react";
import Footer from "./layout/footer";
import Modal_Share from "../../public/img/modal_share.svg";
import Faq from "./components/faq";
import Feature from "./components/feature";
import About_Us from "./components/about-us";
import Seo from "../../utils/Seo";
import Home from "./components/home";

const Features = () => {
    useEffect(() => {
        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        };

        setTimeout(scrollToTop, 0);
    }, []);

    return (
        <>
            <Seo dynamic={true}
                title={"Boost Your Discord Community with Join4Join - Gain Members, Enhance Engagement."}
                description={"Grow your Discord community with Join4Join. Gain members, enhance engagement, and boost your server's success. Explore our powerful features today!"}
            />
            <Feature />
            <About_Us />
            <Faq />
            <Home />
            <Footer />
        </>
    );
};

export default Features;