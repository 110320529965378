import request from '../request'

/**
 * Get Server info
 * @param {string} id - id of the server
**/

async function getServerInfo(id) {
    return request('/join4join/info/' + id)
}

export default getServerInfo