import { useEffect } from "react";
import Footer from "./layout/footer";
import Modal_Share from "../../public/img/modal_share.svg";
import Faq from "./components/faq";
import Feature from "./components/feature";
import About_Us from "./components/about-us";
import Seo from "../../utils/Seo";
import Home from "./components/home";

const About = () => {
    useEffect(() => {
        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        };

        setTimeout(scrollToTop, 0);
    }, []);

    return (
        <>
            <Seo dynamic={true}
                title={"About Join4Join - Elevate Your Discord Community with Our Unique Platform"}
                description={"Discover Join4Join, the leading platform for Discord community growth. Learn about our mission, team, and how we empower server owners to thrive."}
            />
            <About_Us />
            <Faq />
            <Home />
            <Feature />
            <Footer />
        </>
    );
};

export default About;