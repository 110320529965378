import request from '../request'

/**
 * Get informations about a token
**/

async function getGuildApiToken(token) {
    return request('/join4join/token/guild/' + token)
}

export default getGuildApiToken