import request from '../request'

/**
 * Purchase an ad
**/

async function purchaseAd(id, members, filter_account, filter_language) {
    return request('/join4join/buy', {
        method: 'POST',
        body: JSON.stringify({
            id,
            members,
            filter_account,
            filter_language
        })
    })
}

export default purchaseAd