import Nav from "../layout/nav";
import Sidebar from "../layout/sidebar";
import Rocket from "../../../public/img/rocket.png";
import { Link, useNavigate } from "react-router-dom";
import Seo from "../../../utils/Seo";

const Join4Join = () => {

    const navigate = useNavigate();

    return (
        <>
            <Seo dynamic={false} />
            <Sidebar />
            <section id="content">
                <main>
                    <h1 className="title">Join4Join</h1>
                    <ul className="breadcrumbs">
                        <li><Link to={"/dashboard"}>Home</Link></li>
                        <li className="divider">/</li>
                        <li><a href="#" className="active">Join4Join</a></li>
                    </ul>
                    <div className="data">
                        <div className="data">
                            <div className="content-data">
                                <div className="cards-container-feature">
                                    <div className="card card-two" onClick={() => { navigate("/dashboard") }}>
                                        <h3>Manage</h3>
                                        <div className="desc">
                                            Use the coins you earned by joining other servers in the farm section to launch a campaign for your server.
                                            Feel free to utilize the filter options to enhance the quality of your advertisements.
                                        </div>
                                        <div className="footer_card"></div>
                                    </div>
                                    <div className="card card-two" onClick={() => { navigate("/dashboard/join4join/farm") }}>
                                        <h3>Farm</h3>
                                        <div className="desc">
                                            The simplest method to accumulate coins is by joining other servers, earning 0.75 coins per server joined. However, please note that if you leave a server before 4 days have passed, you will automatically lose 2 coins.
                                            Additionally, the autofarm feature allows you to join servers automatically.
                                        </div>
                                        <div className="footer_card"></div>
                                    </div>
                                    <div className="card card-two" onClick={() => { navigate("/dashboard/join4join/check") }}>
                                        <h3>Check</h3>
                                        <div className="desc">
                                            The list of servers you can leave without losing coins.
                                            These servers have no restrictions on leaving, and you can safely depart from them without any penalty to your coin balance.
                                        </div>
                                        <div className="footer_card"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </section>
        </>
    );
};

export default Join4Join;