import request from '../request'

/**
 * Farm a server
**/

async function farmServer(id, token) {
    return request('/join4join/farm/join/' + id + `?captchaToken=${token}`)
}

export default farmServer