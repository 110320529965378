import request from '../request'

async function adminSetUserCoins(id, coins) {
    return request('/admin/join4join/user/coins', {
        method: 'POST',
        body: JSON.stringify({
            id, coins
        })
    })
}

export default adminSetUserCoins