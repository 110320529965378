import request from '../request'

/**
 * Get database information on the current user
**/

async function getUserHistory() {
    return request('/join4join/me/history')
}

export default getUserHistory