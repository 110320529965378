import request from '../request'

/**
 * Login with Discord
 * @param {string} code - the Discord authorization code
 */

async function login(code, affiliation, state) {
    return request('/login', {
        method: 'POST',
        body: JSON.stringify({
            code, affiliation, state
        })
    })
}

export default login