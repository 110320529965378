import Seo from "../../../utils/Seo";
import Token_Page from "../components/token";
import Nav from "../layout/nav";
import Sidebar from "../layout/sidebar";

const Token = () => {
    return (
        <>
            <Seo dynamic={false} />
            <Sidebar />
            <section id="content">
                <Token_Page />
            </section>
        </>
    );
};

export default Token;