import Seo from "../../../utils/Seo";
import Check_Page from "../components/check";
import Nav from "../layout/nav";
import Sidebar from "../layout/sidebar";

const Check = () => {
    return (
        <>
            <Seo dynamic={false} />
            <Sidebar />
            <section id="content">
                <Check_Page />
            </section>
        </>
    );
};

export default Check;