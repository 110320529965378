import request from '../request'

/**
 * Receive the link to add the bot
 *  * @param {string} id - id of the server
**/

async function getServerRedirection(id) {
    return request('/discord/redirect_server', {
        method: 'POST',
        body: JSON.stringify({
            id
        })
    })
}

export default getServerRedirection