import getDailyRewards from "../../api/methods/getDailyRewards";
import coinsEffect from "../../utils/coinsEffect";
import Nav from "./layout/nav";
import Sidebar from "./layout/sidebar";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import parsems from 'parse-ms'
import { Link } from "react-router-dom";
import Seo from "../../utils/Seo";
import getUserData from "../../api/methods/getUserData";
import { useEffect } from "react";
import { useState } from "react";
import { SUPPORT_SERVER } from "../../App";

const MySwal = withReactContent(Swal)

const Rewards = () => {
    const [user, setUser] = useState(undefined);

    const getDailyRewards_ = async () => {
        const request = await getDailyRewards();
        if (request && request.success === true) {
            coinsEffect();
            MySwal.fire({
                title: 'Daily Rewards',
                html: <>
                    <p>You claimed your {request.coins} coins daily rewards!</p>
                    <br />
                    <p>Come back in 24 hours</p>
                </>,
                footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                timer: 3000,
                icon: "success"
            })
        } else {
            if (request.data && request.data.id) {
                const time_left = parsems(86400000 - (Date.now() - request.data.daily));
                MySwal.fire({
                    title: 'Daily Rewards',
                    html: <>
                        <p>You already claimed your daily rewards!</p>
                        <br />
                        <p>Come back in <strong>{time_left.hours}h {time_left.minutes}m {time_left.seconds}s</strong></p>
                    </>,
                    footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                    timer: 3000,
                    icon: "error"
                })
            } else {
                MySwal.fire({
                    title: 'Daily Rewards',
                    html: <>
                        <p>An error appear. Please try again</p>
                    </>,
                    footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                    timer: 3000,
                    icon: "error"
                })
            }
        }
    }

    const copyAffiliationLink = () => {
        navigator.clipboard.writeText("https://join4join.xyz/?aff=" + user.id);
        MySwal.fire({
            title: 'Affiliation link Copied',
            footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
            timer: 3000,
            icon: "success"
        })
    }

    const getUserdata_ = async () => {
        const request = await getUserData();
        if (request && request.success === true) {
            setUser(request.data);
        }
    }

    useEffect(() => {
        getUserdata_()
    }, []);

    useEffect(() => {
        const script = document.createElement("script")
        script.src = "https://www.google.com/recaptcha/api.js?render=6LcKKZAjAAAAAN6Rzp8wQU9cwWxi1TIoB9FH_h1E"
        document.body.appendChild(script)
    }, []);

    return (
        <>
            <Seo dynamic={false} />
            <Sidebar />
            <section id="content">
                <main>
                    <h1 className="title">Rewards</h1>
                    <ul className="breadcrumbs">
                        <li><Link to={"/dashboard"}>Home</Link></li>
                        <li className="divider">/</li>
                        <li><a href="#" className="active">Rewards</a></li>
                    </ul>
                    <div className="data">
                        <div className="content-data">
                            <div className="head.all">
                                <h3 className="title">Rewards Page</h3>
                            </div>
                            <div className="cards-container-feature">
                                <div className="card card-two">
                                    <h3>Daily Rewards</h3>
                                    <div className="desc">
                                        <div>
                                            Earn 1 coin every 24 hours just by clicking! Click the button below to claim your daily reward.
                                            Remember to come back every day to keep earning coins consistently.
                                        </div>
                                        <div>
                                            <button onClick={() => { getDailyRewards_() }}>Claim</button>
                                        </div>
                                    </div>
                                    <div className="footer_card"></div>
                                </div>
                                <div className="card card-two">
                                    <h3>Youtube Video</h3>
                                    <div className="desc">
                                        <div>
                                            Make a YouTube video about our site and submit it to our Discord server. You have the opportunity to earn up to 1000 coins for your creative efforts.
                                            Showcase the features, and your personal experience. Tips: Don't hesitate to put your affiliation link in the video description!
                                        </div>
                                        <div>
                                            <a href={SUPPORT_SERVER} target="_blank"><button>Support</button></a>
                                        </div>
                                    </div>
                                    <div className="footer_card"></div>
                                </div>
                                <div className="card card-two">
                                    <h3>Affiliation</h3>
                                    <div className="desc">
                                        <div>
                                            Boost your coin earnings by sharing your referral link! For every person who farms a server using your referral link and creates an account, you'll earn 0.10 coins.
                                            Share your unique link with friend and your community to maximize your earnings.
                                        </div>
                                        <div>
                                            <button onClick={() => { copyAffiliationLink() }}>Copy</button>
                                        </div>
                                    </div>
                                    <div className="footer_card"></div>
                                </div>
                                <div className="card card-two">
                                    <h3>Boost</h3>
                                    <div className="desc">
                                        <div>
                                            Supercharge your daily rewards by boosting our Discord server. Once you boost the server, you'll receive an automatically +10 coins every day.
                                            Enjoy the exclusive benefits of being a server booster and watch your coin earnings grow.
                                        </div>
                                        <div>
                                            <a href={SUPPORT_SERVER} target="_blank"><button>Support</button></a>
                                        </div>
                                    </div>
                                    <div className="footer_card"></div>
                                </div>
                                <div className="card card-two">
                                    <h3>Status</h3>
                                    <div className="desc">
                                        <div>
                                            Join us and boost your experience! By including 'discord.gg/growing' or 'join4join.xyz' in your Discord status, you'll automatically earn 2 coins every hour and unlock a special role.
                                            Level up your presence and rewards within our community
                                        </div>
                                        <div>
                                            <a href={SUPPORT_SERVER} target="_blank"><button>Support</button></a>
                                        </div>
                                    </div>
                                    <div className="footer_card"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </section>
        </>
    );
};

export default Rewards;