import { useEffect } from "react";
import Seo from "../../utils/Seo";

const Bot = () => {
    useEffect(() => {
        setTimeout(() => {
            window.location.replace("https://discord.com/oauth2/authorize?client_id=1080926505078640730&permissions=application.commands&scope=bot")
        }, 2000);
    }, []);

    return (
        <>
            <Seo dynamic={true}
                title={"Join4Join Discord Bot"}
                description={"Add now the official Discord Bot for join4join"}
            />
            <div class="dots-container">
                <div class="dots"></div>
                <div class="dots"></div>
                <div class="dots"></div>
                <div class="dots"></div>
                <div class="dots"></div>
            </div>
        </>
    );
};

export default Bot;