import Seo from "../../../utils/Seo";
import Claim_Page from "../components/claim";
import Nav from "../layout/nav";
import Sidebar from "../layout/sidebar";

const Claim = () => {
    return (
        <>
            <Seo dynamic={false} />
            <Sidebar />
            <section id="content">
                <Claim_Page />
            </section>
        </>
    );
};

export default Claim;