import request from '../request'

/**
 * Create a stripe payement
 *  * @param {string} amount - number of coins requested
**/

async function createPayement(amount) {
    return request('/stripe/payement_create', {
        method: 'POST',
        body: JSON.stringify({
            amount
        })
    })
}

export default createPayement