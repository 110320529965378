import request from '../request'

async function refillAd(guild_id, coins) {
    return request('/join4join/ad/refill', {
        method: 'POST',
        body: JSON.stringify({
            guild_id, coins
        })
    })
}

export default refillAd