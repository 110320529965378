import Seo from "../../../utils/Seo";
import Manage_Page from "../components/manage";
import Nav from "../layout/nav";
import Sidebar from "../layout/sidebar";

const Manage = () => {
    return (
        <>
            <Seo dynamic={false} />
            <Sidebar />
            <section id="content">
                <Manage_Page />
            </section>
        </>
    );
};

export default Manage;