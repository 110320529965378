import request from '../request'

/**
 * Get database information on the current user
**/

async function getUserServers() {
    return request('/discord/me/guilds')
}

export default getUserServers