import request from '../request'

/**
 * Get informations about a token
**/

async function getPayApiToken(token) {
    return request('/join4join/token/pay/' + token)
}

export default getPayApiToken