import { useEffect } from "react";
import Footer from "./layout/footer";
import Faq from "./components/faq";
import Feature from "./components/feature";
import About_Us from "./components/about-us";
import Seo from "../../utils/Seo";
import Home from "./components/home";

const Index = () => {
    return (
        <>
            <Seo dynamic={false} />
            <Home />
            <About_Us />
            <Faq />
            <Footer />
        </>
    );
};

export default Index;