import request from '../request'

async function claimPayApiToken(token) {
    const script = document.createElement("script")
    script.src = "https://www.google.com/recaptcha/api.js?render=6LcKKZAjAAAAAN6Rzp8wQU9cwWxi1TIoB9FH_h1E"
    document.body.appendChild(script)
    return new Promise(resolve => {
        window.grecaptcha.ready(async () => {
            window.grecaptcha
                .execute("6LcKKZAjAAAAAN6Rzp8wQU9cwWxi1TIoB9FH_h1E")
                .then(async (token_) => {
                    resolve(request('/join4join/token/pay/' + token + '/claim' + `?captchaToken=${token_}`));
                })
        })
    })
}

export default claimPayApiToken