import getUserData from "../api/methods/getUserData";

async function isAdmin() {
    const checkUser = await getUserData();
    if (checkUser && checkUser.success === true) {
        if (checkUser.data.permissions === 0) {
            return window.location.replace("/dashboard");
        }
    }
}

export default isAdmin