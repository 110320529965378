import request from '../request'

/**
 * Get all farm servers
**/

async function getUserFarmAll(token) {
    return request('/join4join/me/farm/all', {
        method: 'POST',
        body: JSON.stringify({
            token
        })
    })
}

export default getUserFarmAll