import request from '../request'

/**
 * Get check servers
**/

async function getUserCheck() {
    return request('/join4join/me/check')
}

export default getUserCheck