import { Link } from "react-router-dom";
import Logo from "../../../public/img/logoj4j.png";
import { SUPPORT_SERVER } from "../../../App";

const Footer = () => {
    return (
        <>
            <footer>
                <div className="section_footer">
                    <div className="footer_position">
                        <div style={{ display: "flex" }}>
                            <div className="section_redirection">
                                <h2>About Us</h2>
                                <Link to={"/about"}>Our goals</Link>
                                <a href="https://blog.join4join.xyz" target="_blank">Blog</a>
                                <Link to={"/faq"}>F.A.Q</Link>
                                <Link to={"/documentation"}>API documentation</Link>
                            </div>
                            <div className="section_redirection">
                                <h2>Legals</h2>
                                <Link to={"/terms"}>Terms Of Service</Link>
                                <Link to={"/privacy"}>Privacy Policy</Link>
                            </div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div className="section_redirection">
                                <h2>Social Media</h2>
                                {/* <a href="https://t.me/join4joindiscord" target="_blank">Telegram</a> */}
                                <a href={SUPPORT_SERVER} target="_blank">Discord</a>
                                <a href="https://twitter.com/join4join_off" target="_blank">Twitter</a>
                            </div>
                            <div className="section_redirection">
                                <h2>Partners</h2>
                                <a href="https://bot-hosting.net" target="blank_">Free Host For Discord Bots</a>
                                <a href="https://discord.com/api/oauth2/authorize?client_id=1153715777594200074&permissions=2147601537&scope=applications.commands%20bot" target="_blank">Bumper Discord Bot</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <img src={Logo} alt="join4join website logo" height={30} width={30} /><p>© Copyright 2022 by Join4Join. All rights reserved.</p>
                </div>
            </footer>
        </>
    );
};

export default Footer;