import { useNavigate, Link } from "react-router-dom";
import Nav from "../layout/nav";
import Sidebar from "../layout/sidebar";
import getUserCheck from "../../../api/methods/getUserCheck";
import { useState } from "react";
import { useEffect } from "react";
import Loading from "../../../utils/loading";
import refreshRewards from "../../../api/methods/refreshRewards";
import coinsEffect from "../../../utils/coinsEffect";

const Claim_Page = () => {
    const [load, setLoad] = useState(undefined);
    const navigate = useNavigate()

    const refreshRewards_ = async () => {
        setLoad(true);
        const request = await refreshRewards();
        if (request && request.success === true) {
            setLoad(false);
            coinsEffect()
        }

        navigate("/dashboard/join4join/farm");
    }

    return (
        <>
            <main>
                <h1 className="title">Claim</h1>
                <ul className="breadcrumbs">
                    <li><Link to={"/dashboard/join4join"}>Join4Join</Link></li>
                    <li className="divider">/</li>
                    <li><a href="#" className="active">Claim</a></li>
                </ul>
                <div className="data">
                    <div className="content-data">
                        <div className="head.all">
                            <h3 className="title">Claim page</h3>
                        </div>
                        <div className="db-container background">
                            {
                                load ? <Loading /> :
                                    <>
                                        <div>
                                            <h3>Claim your coins after joining servers using our system.</h3>
                                            <div className="claim_div">
                                                <button onClick={() => { refreshRewards_() }}>Claim Coins</button>
                                                <button onClick={() => { navigate("/dashboard/premium") }}>Purchase Coins</button>
                                            </div>
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Claim_Page;