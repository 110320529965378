import { useEffect } from "react";
import Seo from "../../utils/Seo";
import Footer from "./layout/footer";
import { SUPPORT_SERVER } from "../../App";

const Privacy = () => {
    useEffect(() => {
        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        };

        setTimeout(scrollToTop, 0);
    }, []);

    return (
        <>
            <Seo dynamic={false} />
            <div className="legals">
                <h1>PRIVACY POLICY AND COOKIES POLICY | LAST MODIFICATION: 05/07/2023</h1>
                <h5>By using our services/website, you agree to abide by our GENERAL TERMS AND CONDITIONS OF USE, as well as our PRIVACY/COOKIES POLICY.</h5>
                <br />
                <h2>1. CONDITIONS OF SALE</h2>
                <br />
                <h4>Payment Gateway</h4>
                <p>You are not allowed to chargeback or request a refund through our Payment Gateways (e.g., PayPal, others) as the chargeback fee would be too high to manage. Any issues or disputes can be resolved through our Refund Policy. If a chargeback or Payment Gateways refund is initiated, it will result in the termination of your Join4join account and services. You will not be able to recover your account or services unless you pay the chargeback fee + the amount paid for the service + a 10% fee due to the chargeback situation.</p>
                <h4>Cancellation of the Service</h4>
                <p>By canceling your service, you agree to forfeit everything you have purchased on our site.</p>
                <h4>Refund Policy</h4>
                <p>You can request a complete refund within 24 hours. However, you may need to provide a valid reason for your refund request, and certain services may have specific refund conditions.</p>
                <br />
                <h2>3. PRIVACY POLICY</h2>
                <br />
                <h4>What Information Do We Collect from Customers and Visitors?</h4>
                <p>We collect the following information: your Discord ID. By using our service, you must agree to the terms of use and privacy policy of Google Analytics.</p>
                <h4>How Do We Secure and Protect the Collected Data?</h4>
                <p>We use alternative services to store your data. For your email and username, we use Discord. In our database, we only store the minimum information about you (Discord information).</p>
                <h4>How Can I Delete My Information?</h4>
                <p>To delete or change your information, please contact us via our <a href={SUPPORT_SERVER}>Discord Server</a>.</p>
                <br />
                <h2>4. COOKIES POLICY</h2>
                <br />
                <h4>Do We Use Cookies?</h4>
                <p>Yes, we use cookies to enhance customer experience, provide services, and improve the website.</p>
                <h4>How Can I Delete Cookies?</h4>
                <p>Please refer to your browser's tutorial on how to erase cookies.</p>
                <h4>Google Analytics</h4>
                <p>We use Google Analytics to gather statistics from our website. By using our services, you agree to have their cookies and provide them with certain data.</p>
                <br />
                <h2>5. INTELLECTUAL PROPERTY</h2>
                <p>The brands, logos, signs, as well as all the contents of the site (texts, images, sound, etc.), are protected by the Intellectual Property Code, including copyright.</p>
                <p>The user must request prior authorization from the site for any reproduction, publication, or copy of different contents.</p>
                <p>Users undertake to use the contents of the site strictly for personal purposes. Any commercial or advertising use is strictly prohibited.</p>
                <p>Any total or partial representation of this site by any means whatsoever, without the express authorization of the website operator, would constitute an infringement punishable by Article L 335-2 et seq. of the Intellectual Property Code.</p>
                <h2>6. LEGAL MENTIONS</h2>
                <br />
                <p>Site Link: https://join4join.xyz</p>
                <p>Website hosted by: https://contabo.com/</p>
                <p>Contact: <a href={SUPPORT_SERVER}>Discord Server</a></p>
                <p>Siret: 89488500300018</p>
                <br />
                <p>Please read our complete GENERAL TERMS AND CONDITIONS OF USE and PRIVACY/COOKIES POLICY for more detailed information regarding your rights and responsibilities when using our services.</p>
            </div>
            <Footer />
        </>
    );
};

export default Privacy;