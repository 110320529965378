import Nav from "../layout/nav";
import Sidebar from "../layout/sidebar";
import getUserServers from "../../../api/methods/getUserServers";
import Loading from "../../../utils/loading";
import { useEffect } from "react";
import { useState } from "react";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import getGuildApiToken from "../../../api/methods/getGuildApiToken";
import claimGuildApiToken from "../../../api/methods/claimGuildApiToken";
import deleteGuildApiToken from "../../../api/methods/deleteGuildApiToken";
import getPayApiToken from "../../../api/methods/getPayApiToken";
import claimPayApiToken from "../../../api/methods/claimPayApiToken";
import deletePayApiToken from "../../../api/methods/deletePayApiToken";
import tokenName from "../../../utils/tokenName";
import coinsEffect from "../../../utils/coinsEffect";
import { Link } from "react-router-dom";
import { SUPPORT_SERVER } from "../../../App";

const MySwal = withReactContent(Swal)

const url = "/token/" + window.location.pathname.split("/")[4] + "/" + window.location.pathname.split("/")[5]

const Token_Page = () => {
    const login_ = window.localStorage.getItem("token") ? true : false
    if (login_ === false) {
        window.location.replace(window.location.origin + "/login/discord")
        window.localStorage.setItem("callback", url);
    }

    const [load, setLoad] = useState(undefined);
    const [tokenData, setTokenData] = useState([]);

    const getGuildToken = async (token) => {
        setLoad(true);
        const checkToken = await getGuildApiToken(token);
        if (checkToken && checkToken.success === true) {
            setLoad(false);
            setTokenData(checkToken.data)
        } else {
            window.location.replace("/")
        }
    }

    const claimGuildToken = async (token) => {
        const claimToken = await claimGuildApiToken(token);
        if (claimToken && claimToken.success === true) {
            MySwal.fire({
                title: 'System',
                html: <>
                    <p>Done you successfully run your ad.</p>
                </>,
                footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                timer: 3000,
                icon: "success"
            })
            coinsEffect();
            setTimeout(() => {
                window.location.replace("/dashboard/join4join/manage/" + tokenData.server_id);
            }, 3000);
        } else {
            MySwal.fire({
                title: 'System',
                html: <>
                    <p>Something went wrong.</p>
                </>,
                footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                timer: 3000,
                icon: "success"
            })
        }
    }

    const deleteGuildToken = async (token) => {
        const deleteToken = await deleteGuildApiToken(token);
        if (deleteToken && deleteToken.success === true) {
            MySwal.fire({
                title: 'System',
                html: <>
                    <p>Done you successfully delete your ad.</p>
                </>,
                footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                timer: 3000,
                icon: "success"
            })
            setTimeout(() => {
                window.location.replace("/");
            }, 3000);
        } else {
            MySwal.fire({
                title: 'System',
                html: <>
                    <p>Something went wrong.</p>
                </>,
                footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                timer: 3000,
                icon: "success"
            })
        }
    }

    const getPayToken = async (token) => {
        setLoad(true);
        const checkToken = await getPayApiToken(token);
        if (checkToken && checkToken.success === true) {
            setLoad(false);
            setTokenData(checkToken.data)
        } else {
            window.location.replace("/")
        }
    }

    const claimPayToken = async (token) => {
        const claimToken = await claimPayApiToken(token);
        if (claimToken && claimToken.success === true) {
            MySwal.fire({
                title: 'System',
                html: <>
                    <p>Done you successfully run your coins transfer.</p>
                </>,
                footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                timer: 3000,
                icon: "success"
            })
            coinsEffect();
            setTimeout(() => {
                window.location.replace("/dashboard/join4join/history");
            }, 3000);
        } else {
            MySwal.fire({
                title: 'System',
                html: <>
                    <p>Something went wrong.</p>
                </>,
                footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                timer: 3000,
                icon: "success"
            })
        }
    }

    const deletePayToken = async (token) => {
        const deleteToken = await deletePayApiToken(token);
        if (deleteToken && deleteToken.success === true) {
            MySwal.fire({
                title: 'System',
                html: <>
                    <p>Done you successfully delete your coins transfer.</p>
                </>,
                footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                timer: 3000,
                icon: "success"
            })
            setTimeout(() => {
                window.location.replace("/");
            }, 3000);
        } else {
            MySwal.fire({
                title: 'System',
                html: <>
                    <p>Something went wrong.</p>
                </>,
                footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                timer: 3000,
                icon: "success"
            })
        }
    }

    useEffect(() => {
        if (tokenName() === "buy" && login_ === true) {
            getGuildToken(window.location.pathname.split("/")[5]);
        }
        if (tokenName() === "pay" && login_ === true) {
            getPayToken(window.location.pathname.split("/")[5]);
        }

        const script = document.createElement("script")
        script.src = "https://www.google.com/recaptcha/api.js?render=6LcKKZAjAAAAAN6Rzp8wQU9cwWxi1TIoB9FH_h1E"
        document.body.appendChild(script)
    }, []);

    return (
        <>
            <main>
                <h1 className="title">Token</h1>
                <ul className="breadcrumbs">
                    <li><Link to={"/dashboard/join4join"}>Join4Join</Link></li>
                    <li className="divider">/</li>
                    <li><a href="#" className="active">Token</a></li>
                </ul>
                <div className="data">
                    <div className="content-data">
                        <div className="head.all">
                            <h3 className="title">Confirm the action</h3>
                            <div className="db-container background">
                                <div className="form">
                                    {
                                        tokenName() === "buy" ?
                                            load ? <Loading /> :
                                                <>
                                                    <h2>{tokenData.name}</h2>
                                                    <h1>Buy Members</h1>
                                                    <div className="send_premium_form">
                                                        <input class="input buy_number" value={tokenData.coins} type="text" autoComplete="off" disabled />
                                                    </div>
                                                    <div className="ad_informations">
                                                        <span>Filter alts: {tokenData.filter_alts && tokenData.filter_alts === 1 ? "on" : "off"}</span>
                                                        <span>Filter language: {!["en", "fr", "tr"].includes(tokenData.filter_lang) ? "all" : tokenData.filter_lang}</span>
                                                        <span>Mode: invite</span>
                                                    </div>
                                                    <div className="ad_action">
                                                        <a><button onClick={() => { claimGuildToken(window.location.pathname.split("/")[5]) }}>Save and Run</button></a>
                                                        <a><button className="btn-red" onClick={() => { deleteGuildToken(window.location.pathname.split("/")[5]) }}>Cancel and Delete</button></a>
                                                    </div>
                                                </>
                                            : undefined
                                    }
                                    {
                                        tokenName() === "pay" ?
                                            load ? <Loading /> :
                                                <>
                                                    <h2>{tokenData.name}</h2>
                                                    <h1>Transfer coins</h1>
                                                    <span>Amount</span>
                                                    <input class="input buy_number" value={tokenData.coins} type="text" autoComplete="off" disabled />
                                                    <span>Friend ID</span>
                                                    <input class="input buy_number" value={tokenData.user_receiver} type="text" autoComplete="off" disabled />
                                                    <div className="ad_action">
                                                        <a><button onClick={() => { claimPayToken(window.location.pathname.split("/")[5]) }}>Save and Run</button></a>
                                                        <a><button className="btn-red" onClick={() => { deletePayToken(window.location.pathname.split("/")[5]) }}>Cancel and Delete</button></a>
                                                    </div>
                                                </>
                                            : undefined
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Token_Page;