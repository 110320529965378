import Nav from "../layout/nav";
import Sidebar from "../layout/sidebar";
import getUserServers from "../../../api/methods/getUserServers";
import Loading from "../../../utils/loading";
import { useEffect } from "react";
import { useState } from "react";
import getServerInfo from "../../../api/methods/getServerInfo";
import editAd from "../../../api/methods/editAd";
import getGuildData from "../../../api/methods/getGuildData";
import coinsEffect from "../../../utils/coinsEffect";
import refillAd from "../../../api/methods/refillAd";
import getInviteData from "../../../api/methods/getInviteData";
import editType from "../../../api/methods/editType";
import purchaseAd from "../../../api/methods/purchaseAd";
import getServerRedirection from "../../../api/methods/getServerRedirection";
import getUserData from "../../../api/methods/getUserData";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Seo from "../../../utils/Seo";
import deleteAd from "../../../api/methods/deleteAd";
import { SUPPORT_SERVER } from "../../../App";

const Manage_ID_Page = () => {
    const [serverInfo, setServerInfo] = useState([]);
    const [userInfo, setUserInfo] = useState([]);
    const [server, setServer] = useState([]);
    const [serverSelection, setServerSelection] = useState([]);
    const [redirection, setRedirection] = useState(undefined);
    const [error, setError] = useState(undefined);
    const [block, setBlock] = useState(undefined);
    const [load, setLoad] = useState(undefined);
    const [invite, setInvite] = useState(undefined);
    const [invite_data, setInviteData] = useState(undefined);


    const getUser_data_ = async () => {
        const user_data = await getUserData();
        if (user_data && user_data.success === true) {
            setUserInfo(user_data.data);
        }
    }

    const getServerInfo_ = async (id) => {
        const server_info = await getServerInfo(id);
        if (server_info && server_info.success === true) {
            setLoad(false);
            setServerInfo(server_info.data);
        }
    }

    const getUserServers_ = async (id) => {
        const guilds = await getUserServers(id);
        if (guilds && guilds.success === true) {
            setServer(guilds.data ? guilds.data.filter(x => x.id === id)[0] : []);
            setServerSelection(guilds.data.filter(u => (u.permissions & 2146958591) === 2146958591).map(x => x.id));
        } else if (guilds && guilds.success === false) {
            window.localStorage.removeItem("token")
            window.location.replace("/");
        }
    }

    const getRedirection = async (id) => {
        const redirection_link = await getServerRedirection(id);
        if (redirection_link && redirection_link.success === true) {
            setRedirection(redirection_link.data);
        }
    }

    const purchaseAd_ = async () => {
        if (block) return;
        setLoad(true);
        const element = document.getElementById("buy_number");
        const filter_alts = document.getElementById("filter_alts");
        const filter_lang = document.getElementById("filter_lang");
        const checkAd = await purchaseAd(window.location.pathname.slice(28), Number(element.value), filter_alts.checked, filter_lang.value);
        if (checkAd && checkAd.success === true) {
            const server_info = await getServerInfo(window.location.pathname.slice(28));
            if (server_info && server_info.success === true) {
                setServerInfo(server_info.data);
                coinsEffect();
                setError(undefined);
                setLoad(false);
            }
        } else {
            Swal.fire('Error!', `Missing informations. Try again.`, 'error')
            setLoad(false);
        }
    }

    const saveInviteLink = async () => {
        if (block && invite) {
            setLoad(true)
            const checkType = await editType(window.location.pathname.slice(28), "invite", invite, invite_data.guild.name, invite_data.guild.icon);
            if (checkType && checkType.success === true) {
                const server_info = await getServerInfo(window.location.pathname.slice(28));
                if (server_info && server_info.success === true) {
                    setServerInfo(server_info.data);
                    setLoad(false);
                }
            }
        }
    }

    const editPurchaseMode = async (element) => {
        if (element && element.target && element.target.value) {
            if (element.target.value === "bot") {
                var add_bot = document.getElementById("add_bot");
                var add_invite = document.getElementById("add_invite");
                add_bot.style.display = "block"
                add_invite.style.display = "none"
            } else if (element.target.value === "invite") {
                var add_bot = document.getElementById("add_bot");
                var add_invite = document.getElementById("add_invite");
                add_bot.style.display = "none"
                add_invite.style.display = "block"
            }
        }
    }

    const verifInvite = async (element) => {
        const regexResult = new RegExp("https(:)\/\/discord.gg\/[a-zA-Z0-9]+").exec(element.target.value)
        const regexUrl = new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?").exec(element.target.value)
        if (regexResult && regexUrl) {
            const invite = await getInviteData(element.target.value.split("/")[3]);
            if (invite && invite.success === true && invite.data && invite.data.guild && invite.data.guild.id === window.location.pathname.slice(28) && invite.data.expires_at === null) {
                setError(undefined);
                setInvite(element.target.value);
                setInviteData(invite.data);
                setBlock("go");
            } else {
                setError("Please provide a valid discord invite with no expire date and unlimited use.")
                setBlock(undefined)
                setInvite(undefined);
            }
        } else {
            setError("Please provide a valid discord invite with no expire date and unlimited use.")
        }
    }

    const calcMembers = () => {
        const element = document.getElementById("buy_number");
        const filter_alts = document.getElementById("filter_alts");
        const filter_lang = document.getElementById("filter_lang");

        var buy_member = Number(element.value);
        if (Number(userInfo.money) < buy_member) {
            setBlock(true)
            setError("You don't have enough coins.");
        } else {
            if (filter_alts && filter_alts.checked && filter_alts.checked === true) {
                buy_member = parseInt(Number(element.value) - Number(element.value) * 22 / 100)
            }

            if (filter_lang && filter_lang.value && filter_lang.value !== "all") {
                buy_member = parseInt(Number(buy_member) - Number(buy_member) * 22 / 100)
            }

            if (typeof (Number(buy_member)) === "number" && Number(buy_member) !== 0) {
                if (isNaN(parseInt(buy_member))) {
                    setBlock(true)
                    setError("Please enter a number.");
                } else {
                    if (Number(buy_member) < 0) {
                        setBlock(true)
                        setError('You will receive ' + 0 + ' member.');
                    } else {
                        if (Number(buy_member) < 3) {
                            setBlock(true)
                            setError('Please you must buy an advertisement of at least 3 coins.');
                        } else {
                            setBlock(undefined)
                            setError('You will receive ' + parseInt(buy_member) + ' members.');
                        }
                    }
                }
            } else {
                setBlock(true)
                setError('You will receive ' + 0 + ' member');
            }
        }
    }

    const deletead = async () => {
        const checkDelete = await deleteAd(window.location.pathname.slice(28))
        if (checkDelete && checkDelete.success === true) {
            const server_info = await getServerInfo(window.location.pathname.slice(28));
            if (server_info && server_info.success === true) {
                setServerInfo(server_info.data);
                coinsEffect();
            }
        }
    }

    const refillad_ = async () => {
        const { value: number } = await Swal.fire({
            title: 'Add coins to this current ad.',
            input: 'number',
            icon: "question",
            inputPlaceholder: 'Number of coins'
        })

        if (number) {
            if (!number || isNaN(number) || !parseInt(number) || number < 1) {
                Swal.fire('Error!', `Please provide an amount of coins`, 'error')
            } else {
                if (userInfo && userInfo.money >= number) {
                    const checkRefill = await refillAd(window.location.pathname.slice(28), number)
                    if (checkRefill && checkRefill.success === true) {
                        Swal.fire('Refill ad', `You added successfully ${number} in the ad.`, 'success')
                        const server_info = await getServerInfo(window.location.pathname.slice(28));
                        if (server_info && server_info.success === true) {
                            setServerInfo(server_info.data);
                            coinsEffect();
                        }
                    }
                } else {
                    Swal.fire('Error!', `You don't have enough coins`, 'error')
                }
            }
        }
    }

    const editad_ = async () => {
        const guildData = await getGuildData(window.location.pathname.slice(28))
        if (guildData && guildData.success === true) {

            const { value: editForm } = await Swal.fire({
                title: 'Edit your ad',
                html: `
            <input id="swal-input1" class="swal1-input" name="filter_alts" value="filter_alts" type="checkbox" ${guildData.data.filter_alts === 1 ? "checked" : undefined}><span>Filter alts account</span>
            <div>
            <h4>Choose a language:</h4>
            <select id="swal-input2" class="swal2-input">
                <option value="all">All language</option>
                <option value="fr" ${guildData.data.filter_lang === "fr" ? "selected" : undefined}>Français</option>
                <option value="tr" ${guildData.data.filter_lang === "tr" ? "selected" : undefined}>Türkçe</option>
                <option value="en" ${guildData.data.filter_lang === "en" ? "selected" : undefined}>English</option>
            </select>
            </div>
            <div>
            <h4>Edit the mode:</h4>
            <select id="swal-input3" class="swal2-input">
                <option value="bot" ${guildData.data.type === "bot" ? "selected" : undefined}>Bot</option>
                <option value="invite" ${guildData.data.type === "invite" ? "selected" : undefined}>Invite</option>
            </select>
            </div>
            `,
                footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
                focusConfirm: false,
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "save",
                preConfirm: () => {
                    return [
                        document.getElementById('swal-input1').checked,
                        document.getElementById('swal-input2').value,
                        document.getElementById('swal-input3').value
                    ]
                }
            })

            if (editForm) {
                const filter_alts = editForm[0]
                const filter_lang = editForm[1]
                const type = editForm[2]

                const checkEdit = await editAd(window.location.pathname.slice(28), filter_alts === true ? 1 : 0, filter_lang, type)
                if (checkEdit && checkEdit.success === true) {
                    Swal.fire('Edit ad', `You have successfully edit your ad.`, 'success')
                    const server_info = await getServerInfo(window.location.pathname.slice(28));
                    if (server_info && server_info.success === true) {
                        setServerInfo(server_info.data);
                        coinsEffect();
                    }
                }
            }
        }
    }

    const deletead_ = () => {
        Swal.fire({
            title: 'Are you sure you want to delete your ad and get refund?',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: `Cancel`,
            icon: "question",
            footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire('Ad deleted!', 'You have received a refund', 'success')
                deletead();
            }
        })
    }

    useEffect(() => {
        getServerInfo_(window.location.pathname.slice(28));
        getUserServers_(window.location.pathname.slice(28));
        getUser_data_();
        setLoad(true);
        getRedirection(window.location.pathname.slice(28));
    }, []);

    return (
        <>
            <main>
                <h1 className="title">Manage</h1>
                <ul className="breadcrumbs">
                    <li><Link to={"/dashboard/join4join"}>Join4Join</Link></li>
                    <li className="divider">/</li>
                    <li><a href="#" className="active">Manage</a></li>
                </ul>
                <div className="data">
                    <div className="content-data">
                        <div className="head.all">
                            <h3 className="title">Manage Server</h3>
                            <div className="db-container background">
                                <div className="form">
                                    {load ? <Loading /> :
                                        <>
                                            <img className="ad_picture" src={server?.icon ? `https://cdn.discordapp.com/icons/${server?.id}/${server?.icon}` : `https://dummyimage.com/400x400/000/ffffff&text=${server?.name}`} alt={server?.name} title={server?.name} />
                                            <h2>{server?.name}</h2>
                                            {
                                                serverInfo && serverInfo[0] === 3 ?
                                                    <>
                                                        <h1 style={{ color: "#6a81ff" }}>Start a campaign:</h1>
                                                        <h3>Amount of joins (1 join costs 1 coin)</h3>
                                                        <div>
                                                            {error ? <p className="premium_p">{error}</p> : undefined}
                                                        </div>
                                                        <input onInput={calcMembers} class="input buy_number" type="text" id="buy_number" placeholder="Example: 500" name='buy_number' autoComplete="off" required />
                                                        <div className="toggles-flex">
                                                            <div className="toggles">
                                                                <label className="switch">
                                                                    <input onChange={calcMembers} type="checkbox" name='filter_alts' id='filter_alts' />
                                                                    <div className="slider round"></div>
                                                                </label>
                                                            </div>
                                                            <span>Disable fake accounts</span>
                                                        </div>
                                                        <select name="filter_lang" id="filter_lang" onSelect={calcMembers} onChange={calcMembers}>
                                                            <option value="all">All language</option>
                                                            <option value="fr">Français</option>
                                                            <option value="tr">Türkçe</option>
                                                            <option value="en">English</option>
                                                        </select>
                                                        <a><button onClick={purchaseAd_} class="button button1">Save</button></a>
                                                    </>
                                                    : undefined
                                            }
                                            {
                                                serverInfo && serverInfo[0] === 2 ?
                                                    <>
                                                        <label style={serverSelection.includes(window.location.pathname.slice(28)) ? { display: "block" } : { display: "none" }}>Choose the way to start your ad</label>
                                                        <select onChange={editPurchaseMode.bind(this)} onInput={editPurchaseMode} style={serverSelection.includes(window.location.pathname.slice(28)) ? { display: "block" } : { display: "none" }}>
                                                            <option value="bot">Add the bot</option>
                                                            <option value="invite">Use a discord invite</option>
                                                        </select>
                                                        <div id="add_bot" style={serverSelection.includes(window.location.pathname.slice(28)) ? { display: "block" } : { display: "none" }}>
                                                            <p class="premium_p">Add the bot to start your ads.</p>
                                                            <a href={redirection}><button type="submit" class="button button1" onClick={() => { window.localStorage.setItem("callback", "/dashboard/join4join/manage/" + window.location.pathname.slice(28)) }}>Add the bot</button></a>
                                                        </div>
                                                        <div id="add_invite" style={serverSelection.includes(window.location.pathname.slice(28)) ? { display: "none" } : { display: "block" }}>
                                                            <div>
                                                                {error ?
                                                                    <>
                                                                        <div className="position_tips">
                                                                            <i className="fa-solid fa-triangle-exclamation"></i><p style={{ color: "#ff6a6a" }}>{error}</p>
                                                                        </div>
                                                                    </> : <>
                                                                        <div className="position_tips">
                                                                            <i className="fa-solid fa-triangle-exclamation"></i><p style={{ color: "#ff6a6a" }}>Note: The use of the invite mode will take longer than the bot mode.</p>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                <input onInput={verifInvite.bind(this)} type="text" class="input buy_number" placeholder={`Enter an invitation for ${server.name}`} autoComplete="off" required />
                                                                <a><button onClick={saveInviteLink} class="button button1">Save</button></a>
                                                            </div>
                                                        </div>
                                                    </> : undefined
                                            }
                                            {
                                                serverInfo && serverInfo[0] === 1 ?
                                                    serverSelection && serverSelection.includes(window.location.pathname.slice(28)) || serverInfo[2].buyer === userInfo.id ?
                                                        <>
                                                            <div className="ad_informations">
                                                                <span>Filter alts: {serverInfo[2].filter_alts && serverInfo[2].filter_alts === 1 ? "on" : "off"}</span>
                                                                <span>Filter language: {!["en", "fr", "tr"].includes(serverInfo[2].filter_lang) ? "all" : serverInfo[2].filter_lang}</span>
                                                                <span>Mode: {serverInfo[2].type}</span>
                                                            </div>
                                                            <p class="premium_p">{serverInfo[1]}</p>
                                                            <div className="ad_action row">
                                                                <a><button onClick={() => { editad_() }}>Edit</button></a>
                                                                <a><button onClick={() => { refillad_() }}>Refill</button></a>
                                                                <a><button className="btn-red" onClick={() => { deletead_() }}>Delete</button></a>
                                                            </div>
                                                            <div>
                                                                <Link to={"/dashboard/premium"}><button class="button button5">Get More Members</button></Link>
                                                            </div>
                                                        </> : <><p className="premium_p">Someone already bought an ad for this server. You don't have the permission to get informations about this ad.</p></> : undefined
                                            }
                                        </>

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Manage_ID_Page;