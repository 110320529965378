import request from '../request'

/**
 * Get api key
**/

async function getApiKey() {
    return request('/join4join/me/api')
}

export default getApiKey