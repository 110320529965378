import Seo from "../../../utils/Seo";
import Manage_ID_Page from "../components/manage_id";
import Nav from "../layout/nav";
import Sidebar from "../layout/sidebar";

const Manage_ID = () => {
    return (
        <>
            <Seo dynamic={false} />
            <Sidebar />
            <section id="content">
                <Manage_ID_Page />
            </section>
        </>
    );
};

export default Manage_ID;