import getUserData from "../api/methods/getUserData";

async function coinsEffect() {
    const coins = await getUserData();
    if (coins && coins.success === true) {
        var i = parseFloat(document.getElementById("coins").innerText)
        const number_to_add = parseFloat(document.getElementById("coins").innerText)
        const interval = setInterval(frame, 100);
        function frame() {
            if (i === parseFloat(coins.data.money)) {
                document.getElementById("coins").innerText = coins.data.money
                clearInterval(interval);
            } else {
                if (number_to_add > parseFloat(coins.data.money)) {
                    if (i <= parseFloat(coins.data.money)) {
                        document.getElementById("coins").innerText = coins.data.money
                        clearInterval(interval);
                    } else {
                        document.getElementById("coins").innerText = i
                        i--
                    }
                } else if (number_to_add < parseFloat(coins.data.money)) {
                    if (i >= parseFloat(coins.data.money)) {
                        document.getElementById("coins").innerText = coins.data.money
                        clearInterval(interval);
                    } else {
                        document.getElementById("coins").innerText = i
                        i++
                    }
                }
            }
        }
    }
}

export default coinsEffect