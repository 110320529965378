import React from 'react';
import { Helmet } from 'react-helmet';
import logo from "../public/img/logoj4j.png";

export default function Seo({ dynamic, title, description, image }) {
    if (dynamic) {
        return (
            <Helmet>
                { /* Standard metadata tags */}
                <title>{title}</title>
                <meta name="author" content={title} />
                <meta name='description' content={description} />
                <meta name="keywords" content="Join4Join, Buy discord members, Discord Members, Get Discord Members, grow discord server" />

                { /* End standard metadata tags */}
                { /* Facebook tags */}
                <meta name="og:site_name" content="Join4Join" />
                <meta name="og:url" content="location" />
                <meta property="og:image" content={image ? image : logo} />
                <meta property="og:type" content={"website"} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                { /* End Facebook tags */}
                { /* Twitter tags */}
                <meta name="twitter:creator" content={"@Join4Join_off"} />
                <meta name="twitter:card" content={"website"} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                { /* End Twitter tags */}
                <meta name="apple-mobile-web-app-title" content={title} />
                <meta name="application-name" content={description} />
            </Helmet>
        )
    } else {
        return (
            <Helmet>
                { /* Standard metadata tags */}
                <title>Join4Join | The best j4j bot to get members on Discord</title>
                <meta name="author" content={"Join4Join | The best j4j bot to get members on Discord"} />
                <meta name='description' content={`Grow your Discord Server by thousands of members daily using the official join4join bot on Discord. J4J is the best bot to grow your discord server for free.`} />
                <meta name="keywords" content="Join4Join, Buy discord members, Discord Members, Get Discord Members, grow discord server" />
                { /* End standard metadata tags */}
                { /* Facebook tags */}
                <meta name="og:site_name" content="Join4Join" />
                <meta name="og:url" content="location" />
                <meta property="og:image" content={logo} />
                <meta property="og:type" content={"website"} />
                <meta property="og:title" content={`Join4Join | The best j4j bot on discord to get members`} />
                <meta property="og:description" content={`Grow your Discord Server by thousands of members daily using the official join4join bot on Discord. J4J is the best bot to grow your discord server for free.`} />
                { /* End Facebook tags */}
                { /* Twitter tags */}
                <meta name="twitter:creator" content={"@Join4Join_off"} />
                <meta name="twitter:card" content={"website"} />
                <meta name="twitter:title" content={`Join4Join | The best j4j bot on discord to get members`} />
                <meta name="twitter:description" content={`Grow your Discord Server by thousands of members daily using the official join4join bot on Discord. J4J is the best bot to grow your discord server for free.`} />
                { /* End Twitter tags */}
                <meta name="apple-mobile-web-app-title" content="Join4Join | The best j4j bot on discord to get members" />
                <meta name="application-name" content="Grow your Discord Server by thousands of members daily using the official join4join bot on Discord. J4J is the best bot to grow your discord server for free." />
            </Helmet>
        )
    }
}