import request from '../request'

/**
 * Edit the ad type (bot/invite)
 * @param {string} guild id - the id of the discord server
 * @param {string} ad type - bot or invite
 * @param {string} invite - the invite link of the discord server
 * @param {string} name - guild invite
 * @param {string} icon - guild icon
**/

async function editType(id, type, invite, name, icon) {
    return request('/join4join/buy/type', {
        method: 'POST',
        body: JSON.stringify({
            id, type, invite, name, icon
        })
    })
}

export default editType