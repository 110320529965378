import getDailyRewards from "../../api/methods/getDailyRewards";
import coinsEffect from "../../utils/coinsEffect";
import Nav from "./layout/nav";
import Sidebar from "./layout/sidebar";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import parsems from 'parse-ms'
import { Link } from "react-router-dom";
import Seo from "../../utils/Seo";
import getUserData from "../../api/methods/getUserData";
import { useEffect } from "react";
import { useState } from "react";
import ReactApexChart from 'react-apexcharts';
import statsAffiliation from "../../api/methods/statsAffiliation";
import Loading from "../../utils/loading";
import { useMediaQuery } from "react-responsive";
import { SUPPORT_SERVER } from "../../App";

export function Chart({ dataAffiliation, smallScreen }) {
    const chartData = {
        options: {
            chart: {
                id: 'bar-chart',
            },
            xaxis: {
                categories: smallScreen
                    ? dataAffiliation.slice(-7).map((item) => item.createdAtFormatted)
                    : dataAffiliation.map((item) => item.createdAtFormatted),
                labels: {
                    show: true,
                },
            },
        },
        series: [
            {
                name: 'Affiliations per day',
                data: smallScreen ? dataAffiliation.slice(-7).map((item) => parseInt(item.total)) : dataAffiliation.map((item) => parseInt(item.total)),
            },
        ],
    };

    return (
        <div>
            <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="bar"
                height={600}
            />
        </div>
    );
}

const MySwal = withReactContent(Swal)

const Affiliation = () => {
    const [load, setLoad] = useState(undefined);
    const [dataAffiliation, setDataAffiliation] = useState([]);
    const [user, setUser] = useState(undefined);

    const isSmallScreen = useMediaQuery({ maxWidth: 768 });

    const getUserdata_ = async () => {
        const request = await getUserData();
        if (request && request.success === true) {
            setUser(request.data);
        }
    }

    const getAffiliationData = async () => {
        const request = await statsAffiliation();
        if (request && request.success === true) {
            setDataAffiliation(request.data);
            setLoad(false)
        }
    }

    const copyAffiliationLink = () => {
        navigator.clipboard.writeText("https://join4join.xyz/?aff=" + user.id);
        MySwal.fire({
            title: 'Affiliation link Copied',
            footer: `<a href="${SUPPORT_SERVER}">Support Server</a>`,
            timer: 3000,
            icon: "success"
        })
    }

    useEffect(() => {
        setLoad(true)
        getAffiliationData()
        getUserdata_()
    }, []);

    return (
        <>
            <Seo dynamic={false} />
            <Sidebar />
            <section id="content">
                <main>
                    <h1 className="title">Affiliation</h1>
                    <ul className="breadcrumbs">
                        <li><Link to={"/dashboard"}>Home</Link></li>
                        <li className="divider">/</li>
                        <li><a href="#" className="active">Affiliation</a></li>
                    </ul>
                    <div className="data">
                        <div className="content-data">
                            <div className="head.all">
                                <h3 className="title">Affiliation Page</h3>
                            </div>
                            {load ?
                                <>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <Loading />
                                    </div>
                                </>
                                :
                                <>
                                    <div className="rewards_affiliation">
                                        <h2>Affiliation Link</h2>
                                        <p>Copy your affiliation link and share it. Earn 3 coins per new account from your affiliation link</p>
                                        <div>
                                            <button className="btn" onClick={() => { copyAffiliationLink() }}>Copy</button>
                                        </div>
                                    </div>
                                    <div className="affiliation_chart">
                                        <Chart dataAffiliation={dataAffiliation} smallScreen={isSmallScreen} />
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </main>
            </section>
        </>
    );
};

export default Affiliation;