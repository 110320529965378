import { Link } from "react-router-dom";
import Modal_Share from "../../../public/img/modal_share.svg";

const Home = () => {

    const isLogin = window.localStorage.getItem("token") ? true : false

    return (
        <>
            <div className="lp-container">
                <div className="position">
                    <div className="middle">
                        <div className="padding">
                            <div className="left">
                                <div className="left_position">
                                    <div className="section_title">The Leading Website For Discord Growth</div>
                                    <h1>The #1 Way To Grow Your Discord Server</h1>
                                    <p>We are here to help project creators accomplish their goals and grow their projects through the Discord platform. Join4Join is dedicated to supporting you in achieving your objectives and maximizing your growth potential on Discord.</p>
                                    <Link to={isLogin ? "/dashboard" : "/login"} onClick={window.localStorage.setItem("callback", "/dashboard")}>
                                        <button>Get Started</button>
                                    </Link>
                                </div>
                            </div>
                            <div className="right">
                                <div className="right_position">
                                    <img src={Modal_Share} alt="Users handling icons" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;